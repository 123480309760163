:root {
  --margin-x: 1rem;
  --main-sidebar-width: 4.5rem;
  --sidebar-panel-width: 230px;
  --sidebar-panel-min-width: 64px;
  --ease-in: .4, 0, 1, 1;
  --ease-out: 0, 0, .2, 1;
  --ease-in-out: .4, 0, .2, 1;
}

@media (min-width: 768px) {
  :root {
    --margin-x: 1.5rem;
  }
}

@media (min-width: 1024px) {
  :root {
    --main-sidebar-width: 5rem;
    --sidebar-panel-width: 240px;
  }
}

@media (min-width: 1280px) {
  :root {
    --margin-x: 4rem;
  }

  .has-min-sidebar {
    --margin-x: 2.5rem;
  }

  .is-sidebar-open {
    --margin-x: 1.5rem;
  }
}

html {
  height: 100%;
  text-rendering: optimizelegibility;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

html.dark {
  color-scheme: dark;
}

.cloak {
  display: none !important;
}

body {
  color: #64748b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  background-color: #f8fafc;
  font-family: Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  line-height: 1.25rem;
}

.dark body {
  color: #a3adc2;
  color-scheme: dark;
  background-color: #192132;
}

body.is-monochrome:before {
  pointer-events: none;
  z-index: 999999;
  height: calc(100% + 10rem);
  width: calc(100% + 10rem);
  --tw-backdrop-grayscale: grayscale(100%);
  --tw-backdrop-opacity: opacity(.92);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  --tw-content: "";
  content: var(--tw-content);
  margin: -5rem;
  position: fixed;
  inset: 0;
}

.app-preloader .app-preloader-inner:after, .app-preloader .app-preloader-inner:before {
  height: 100%;
  width: 100%;
  --tw-content: "";
  content: var(--tw-content);
  background-color: #cbd5e140;
  border-radius: 9999px;
  position: absolute;
}

.dark .app-preloader .app-preloader-inner:after, .dark .app-preloader .app-preloader-inner:before {
  background-color: #697a9b80;
}

.app-preloader .app-preloader-inner:after, .app-preloader .app-preloader-inner:before {
  animation: 3s cubic-bezier(.55, .15, .45, .85) infinite spinner-grow;
}

.app-preloader .app-preloader-inner:after {
  animation-delay: -1.5s;
}

.sidebar-toggle span {
  height: 2px;
  width: 1.25rem;
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-color: currentColor;
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.sidebar-toggle span:nth-child(2) {
  width: .75rem;
}

.is-sidebar-open .sidebar-toggle span {
  width: 11px;
  --tw-translate-x: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  margin-left: .5rem;
}

.is-sidebar-open .sidebar-toggle span:nth-child(1) {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.is-sidebar-open .sidebar-toggle span:nth-child(2) {
  display: none;
}

.is-sidebar-open .sidebar-toggle span:nth-child(3) {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.main-sidebar {
  z-index: 40;
  height: 100%;
  width: var(--main-sidebar-width);
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  flex-shrink: 0;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  .main-sidebar {
    z-index: 60;
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.is-sidebar-open .main-sidebar {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.sidebar-panel {
  z-index: 30;
  height: 100%;
  width: calc(var(--main-sidebar-width)  + var(--sidebar-panel-width));
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-shadow: 0 3px 10px 0 #302e380f;
  --tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition: transform .25s cubic-bezier(.4, 0, 1, 1) .15s;
  position: fixed;
  top: 0;
  left: 0;
}

.dark .sidebar-panel {
  --tw-shadow-color: #19213299;
  --tw-shadow: var(--tw-shadow-colored);
}

@media (min-width: 768px) {
  .sidebar-panel {
    transition-delay: unset;
  }
}

.is-sidebar-open .sidebar-panel {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.sidebar-panel-min {
  z-index: 40;
  height: 100%;
  width: var(--sidebar-panel-min-width);
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-shadow: 0 3px 10px 0 #302e380f;
  --tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  flex-shrink: 0;
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  position: fixed;
}

.dark .sidebar-panel-min {
  --tw-shadow-color: #19213299;
  --tw-shadow: var(--tw-shadow-colored);
}

@media (min-width: 768px) {
  .sidebar-panel-min {
    --tw-translate-x: var(--main-sidebar-width);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.is-sidebar-open .sidebar-panel-min {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

nav.header {
  z-index: 20;
  height: 61px;
  width: 100%;
  border-color: #e9eef5;
  border-bottom-width: 1px;
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
}

.dark nav.header {
  border-color: #26334d;
}

@media (min-width: 768px) {
  nav.header {
    width: calc(100% - var(--main-sidebar-width));
  }
}

nav.header:before {
  height: 100%;
  width: calc(100vw - 100%);
  --tw-content: "";
  content: var(--tw-content);
  background-color: #fff;
  position: absolute;
  left: calc(-100vw + 100%);
}

.dark nav.header:before {
  background-color: #222e45;
}

nav.header .header-container {
  padding-left: var(--margin-x);
  padding-right: var(--margin-x);
  transition-property: padding, width;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (min-width: 768px) {
  .has-min-sidebar nav.header {
    width: calc(100% - (var(--main-sidebar-width)  + var(--sidebar-panel-min-width)));
  }
}

@media (min-width: 1280px) {
  .is-sidebar-open nav.header {
    width: calc(100% - (var(--main-sidebar-width)  + var(--sidebar-panel-width)));
  }
}

.is-header-blur nav.header .header-container {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

@supports ((-webkit-backdrop-filter: var(--tw)) or (backdrop-filter: var(--tw))) {
  .is-header-blur nav.header .header-container {
    background-color: #fffc;
  }

  .dark .is-header-blur nav.header .header-container {
    background-color: #222e45cc !important;
  }
}

.is-header-not-sticky nav.header {
  position: absolute;
}

.darkmode-toggle svg {
  transform-origin: top;
  animation: .2s linear forwards darkmode-btn;
}

.darkmode-toggle svg.darkmode-moon {
  display: none;
}

.dark .darkmode-toggle svg.darkmode-moon, .darkmode-toggle svg.darkmode-sun {
  display: block;
}

.dark .darkmode-toggle svg.darkmode-sun {
  display: none;
}

@keyframes darkmode-btn {
  from {
    transform: scale(.75);
  }

  to {
    transform: scale(1);
  }
}

.mobile-searchbar {
  animation: .15s cubic-bezier(0, 0, .2, 1) forwards mobile-searchbar;
}

.mobile-searchbar.animate\:leave {
  animation: .15s cubic-bezier(0, 0, .2, 1) forwards mobile-searchbar-leave;
}

@keyframes mobile-searchbar {
  from {
    opacity: 0;
    transform: scale(1.05);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes mobile-searchbar-leave {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(.95);
  }
}

.main-content {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-content: start;
  margin-top: 60px;
  transition-property: width, margin-left, margin-right, padding-left, padding-right;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  display: grid;
}

@media print {
  .main-content {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .main-content {
    margin-left: var(--main-sidebar-width);
  }

  .has-min-sidebar .main-content {
    margin-left: calc(var(--main-sidebar-width)  + var(--sidebar-panel-min-width));
  }
}

.is-sidebar-open .main-content {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

@media (min-width: 1280px) {
  .is-sidebar-open .main-content {
    margin-left: calc(var(--main-sidebar-width)  + var(--sidebar-panel-width));
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.right-sidebar .right-sidebar-overlay {
  animation: .2s cubic-bezier(0, 0, .2, 1) forwards fade-in;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.right-sidebar .right-sidebar-overlay.animate\:leave {
  animation: .2s cubic-bezier(.4, 0, .2, 1) forwards fade-out;
}

.right-sidebar .right-sidebar-content {
  animation: .2s cubic-bezier(0, 0, .2, 1) forwards right-sidebar-open;
}

.right-sidebar .right-sidebar-content.animate\:leave {
  animation: .2s cubic-bezier(.4, 0, .2, 1) forwards right-sidebar-close;
}

@keyframes right-sidebar-open {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes right-sidebar-close {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

.navigation\:sideblock {
  --sideblock-width: 260px;
}

.navigation\:sideblock .sidebar-panel {
  width: var(--sideblock-width);
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.navigation\:sideblock.is-sidebar-open .sidebar-panel {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.navigation\:sideblock nav.header {
  width: 100%;
}

@media (min-width: 1280px) {
  .navigation\:sideblock.is-sidebar-open nav.header {
    width: calc(100% - (var(--sideblock-width)));
  }
}

.navigation\:sideblock .main-content {
  margin-left: 0;
}

@media (min-width: 1280px) {
  .navigation\:sideblock.is-sidebar-open .main-content {
    margin-left: var(--sideblock-width);
  }
}

.navigation\:horizontal nav.header {
  width: 100%;
}

@media (min-width: 640px) {
  .navigation\:horizontal nav.header {
    height: auto;
  }
}

.navigation\:horizontal .main-content {
  margin-left: 0;
}

@media (min-width: 640px) {
  .navigation\:horizontal .main-content {
    margin-top: 7rem;
  }
}

.h-100vh {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.min-h-100vh {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

.inline-space > :not([hidden]) {
  margin-bottom: .625rem;
  margin-right: .625rem;
}

code.inline-code {
  color: #f000b9;
  background-color: #f1f5f9;
  border-radius: .25rem;
  padding: .125rem .25rem;
  font-size: .8125rem;
  line-height: 1.125rem;
}

.dark code.inline-code {
  color: #ff57d8;
  background-color: #202b40;
}

.is-scrollbar-hidden {
  scrollbar-width: none;
}

.is-scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

@supports selector(::-webkit-scrollbar) {
  .scrollbar-sm::-webkit-scrollbar {
    height: .375rem;
    width: .375rem;
  }

  .scrollbar-sm:hover::-webkit-scrollbar-thumb, .scrollbar-sm:focus-within::-webkit-scrollbar-thumb {
    background-color: #cbd5e1cc;
    border-radius: 9999px;
  }

  .dark .scrollbar-sm:hover::-webkit-scrollbar-thumb, .dark .scrollbar-sm:focus-within::-webkit-scrollbar-thumb {
    background-color: #5c6b8a;
  }

  .scrollbar-sm::-webkit-scrollbar-thumb:hover {
    background-color: #94a3b8;
  }

  .dark .scrollbar-sm::-webkit-scrollbar-thumb:hover {
    background-color: #697a9b;
  }
}

.card {
  min-width: 1px;
  overflow-wrap: break-word;
  color: #64748b;
  --tw-shadow: 0 3px 10px 0 #302e380f;
  --tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.dark .card {
  color: #a3adc2;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #26334d;
}

@media print {
  .card {
    border-width: 1px;
  }
}

.skeleton {
  --sk-color: #ffffff80;
  position: relative;
  overflow: hidden;
}

.dark .skeleton {
  --sk-color: #5c6b8a;
}

.skeleton.animate-wave:after {
  --tw-content: "";
  content: var(--tw-content);
  background: linear-gradient(90deg, transparent, var(--sk-color), transparent);
  animation: 1.5s ease-in-out .5s infinite skeleton-wave;
  position: absolute;
  inset: 0;
}

@keyframes skeleton-wave {
  0% {
    transform: translateX(-100%);
  }

  50%, 100% {
    transform: translateX(100%);
  }
}

.avatar {
  height: 2.5rem;
  width: 2.5rem;
  flex-shrink: 0;
  display: inline-flex;
  position: relative;
}

.avatar > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.avatar > .is-initial {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  display: flex;
}

.spinner.is-grow span {
  animation: 1.5s ease-in-out infinite spinner-grow;
}

.spinner.is-elastic {
  animation-timing-function: cubic-bezier(.53, .21, .29, .67);
  animation-duration: 1.3;
}

.spinner.is-grow span:nth-child(1) {
  animation-delay: -750ms;
}

@keyframes spinner-grow {
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }
}

.form-input, .form-textarea, .form-select, .form-multiselect, .form-radio, .form-checkbox, .form-switch, .form-checkbox:before, .form-radio:before, .form-switch:before {
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.form-input, .form-textarea, .form-select, .form-multiselect {
  appearance: none;
  letter-spacing: .025em;
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.form-input::placeholder, .form-textarea::placeholder, .form-select::placeholder, .form-multiselect::placeholder {
  font-weight: 300;
}

.form-input:focus, .form-textarea:focus, .form-select:focus, .form-multiselect:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.form-input, .form-textarea, .form-select, .form-multiselect {
  contain: paint;
}

.form-select {
  print-print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewbox='0 0 20 20'%3e%3cpath stroke='%235C6B8A' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
}

.form-multiselect {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-print-color-adjust: unset;
  padding-right: .75rem;
}

.form-checkbox, .form-radio, .form-switch {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  appearance: none;
  outline-offset: 2px;
  outline: 2px solid #0000;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.form-checkbox:focus, .form-radio:focus, .form-switch:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.form-checkbox, .form-radio, .form-switch {
  print-print-color-adjust: exact;
}

.form-switch {
  --thumb-border: 2px;
}

.form-switch:before {
  top: var(--thumb-border);
  left: var(--thumb-border);
  height: calc(100% - var(--thumb-border) * 2);
  content: var(--tw-content);
  width: calc((100% / 2)  - (var(--thumb-border) * 2));
  position: absolute;
}

.form-switch:checked:before {
  content: var(--tw-content);
  --tw-translate-x: calc(100% + (var(--thumb-border) * 2));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.form-switch:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.form-switch.is-outline:before {
  content: var(--tw-content);
  width: calc((100% / 2)  - (var(--thumb-border) * 2)  - 1px);
}

.form-switch.is-outline:checked:before {
  content: var(--tw-content);
  --tw-translate-x: calc(100% + (var(--thumb-border) * 2)  + 2px);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.form-radio {
  --tw-thumb: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3.5'/%3e%3c/svg%3e");
}

.form-radio:before {
  content: var(--tw-content);
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.form-checkbox {
  --tw-thumb: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.form-checkbox:before {
  transform-origin: bottom;
  content: var(--tw-content);
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.form-radio, .form-checkbox {
  border-width: 1px;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-block;
}

.form-radio:before, .form-checkbox:before {
  height: 100%;
  width: 100%;
  content: var(--tw-content);
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  inset: 0;
}

.form-radio:checked:before, .form-checkbox:checked:before {
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.form-radio:hover, .form-checkbox:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.form-radio.is-basic, .form-checkbox.is-basic {
  background-origin: border-box;
}

.form-radio.is-basic:before, .form-checkbox.is-basic:before {
  content: var(--tw-content);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: var(--tw-thumb);
}

.form-radio.is-outline:before, .form-checkbox.is-outline:before {
  content: var(--tw-content);
  -webkit-mask-image: var(--tw-thumb);
  mask-image: var(--tw-thumb);
}

.form-range {
  width: 100%;
  appearance: none;
  outline-offset: 2px;
  --range-thumb-size: 1.125rem;
  --range-track-h: .375rem;
  background-color: #0000;
  outline: 2px solid #0000;
  display: block;
}

.form-range::-webkit-slider-thumb {
  height: var(--range-thumb-size);
  width: var(--range-thumb-size);
  cursor: pointer;
  appearance: none;
  background-color: currentColor;
  border-style: none;
  border-radius: 9999px;
  position: relative;
  top: 50%;
}

.form-range::-webkit-slider-thumb:active {
  opacity: .85;
}

.form-range::-webkit-slider-thumb {
  transform: translateY(-50%);
}

.form-range::-moz-range-thumb {
  height: var(--range-thumb-size);
  width: var(--range-thumb-size);
  cursor: pointer;
  appearance: none;
  background-color: currentColor;
  border-style: none;
  border-radius: 9999px;
  position: relative;
  top: 50%;
}

.form-range::-moz-range-thumb:active {
  opacity: .85;
}

.form-range::-moz-range-track {
  height: var(--range-track-h);
  width: 100%;
  background-color: #e9eef5;
  border-radius: 9999px;
}

.dark .form-range::-moz-range-track {
  background-color: #384766;
}

.form-range::-webkit-slider-runnable-track {
  height: var(--range-track-h);
  width: 100%;
  background-color: #e9eef5;
  border-radius: 9999px;
}

.dark .form-range::-webkit-slider-runnable-track {
  background-color: #384766;
}

.btn {
  cursor: pointer;
  text-align: center;
  letter-spacing: .025em;
  outline-offset: 2px;
  border-radius: .5rem;
  outline: 2px solid #0000;
  justify-content: center;
  align-items: center;
  padding: .5rem 1.25rem;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.btn:disabled {
  pointer-events: none;
}

.badge, .tag {
  vertical-align: baseline;
  letter-spacing: .025em;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  padding: .375rem .5rem;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.tag {
  cursor: pointer;
}

.progress {
  width: 100%;
  border-radius: 9999px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.progress .is-indeterminate {
  animation: 2s cubic-bezier(.4, 0, .2, 1) infinite progress-increase;
}

.progress .is-active:before {
  --tw-content: "";
  content: var(--tw-content);
  background-color: #fff;
  border-radius: .25rem;
  animation: 2s cubic-bezier(.55, .2, .3, .67) infinite progress-active;
  position: absolute;
  inset: 0;
}

@keyframes progress-increase {
  from {
    width: 5%;
    left: -5%;
  }

  to {
    width: 100%;
    left: 125%;
  }
}

@keyframes progress-active {
  0% {
    opacity: .4;
    transform: translateX(-100%);
  }

  90% {
  }

  100% {
    opacity: 0;
    transform: translateX(0);
  }
}

.mask {
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.mask.is-squircle {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none' viewbox='0 0 200 200'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none' viewbox='0 0 200 200'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
}

.mask.is-reuleaux-triangle {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='207' height='207' fill='none' viewbox='0 0 207 207'%3E%3Cpath fill='%23000' d='M138.648 181.408C47.268 232.569 1.327 206.607.824 103.52.324.432 46.014-25.148 137.896 26.777c91.882 51.925 92.133 103.469.753 154.631h-.001z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='207' height='207' fill='none' viewbox='0 0 207 207'%3E%3Cpath fill='%23000' d='M138.648 181.408C47.268 232.569 1.327 206.607.824 103.52.324.432 46.014-25.148 137.896 26.777c91.882 51.925 92.133 103.469.753 154.631h-.001z'/%3E%3C/svg%3E");
}

.mask.is-diamond {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='227' height='227' fill='none' viewbox='0 0 227 227'%3E%3Cpath fill='%23000' d='M42.71 42.71c-56.568 56.57-56.568 84.853 0 141.422 56.57 56.569 84.853 56.569 141.422 0s56.569-84.853 0-141.421c-56.569-56.569-84.853-56.569-141.421 0z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='227' height='227' fill='none' viewbox='0 0 227 227'%3E%3Cpath fill='%23000' d='M42.71 42.71c-56.568 56.57-56.568 84.853 0 141.422 56.57 56.569 84.853 56.569 141.422 0s56.569-84.853 0-141.421c-56.569-56.569-84.853-56.569-141.421 0z'/%3E%3C/svg%3E");
}

.mask.is-hexagon {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='182' height='201' fill='none' viewbox='0 0 182 201'%3E%3Cpath fill='%23000' d='M.3 65.486c0-9.196 6.687-20.063 14.211-25.078l61.86-35.946c8.36-5.016 20.899-5.016 29.258 0l61.86 35.946c8.36 5.015 14.211 15.882 14.211 25.078v71.055c0 9.196-6.687 20.063-14.211 25.079l-61.86 35.945c-8.36 4.18-20.899 4.18-29.258 0l-61.86-35.945C6.151 157.44.3 145.737.3 136.54V65.486z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='182' height='201' fill='none' viewbox='0 0 182 201'%3E%3Cpath fill='%23000' d='M.3 65.486c0-9.196 6.687-20.063 14.211-25.078l61.86-35.946c8.36-5.016 20.899-5.016 29.258 0l61.86 35.946c8.36 5.015 14.211 15.882 14.211 25.078v71.055c0 9.196-6.687 20.063-14.211 25.079l-61.86 35.945c-8.36 4.18-20.899 4.18-29.258 0l-61.86-35.945C6.151 157.44.3 145.737.3 136.54V65.486z'/%3E%3C/svg%3E");
}

.mask.is-hexagon-2 {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='182' fill='none' viewbox='0 0 200 182'%3E%3Cpath fill='%23000' d='M64.786 181.4c-9.196 0-20.063-6.687-25.079-14.21L3.762 105.33c-5.016-8.36-5.016-20.9 0-29.259l35.945-61.86C44.723 5.851 55.59 0 64.786 0h71.055c9.196 0 20.063 6.688 25.079 14.211l35.945 61.86c4.18 8.36 4.18 20.899 0 29.258l-35.945 61.86c-4.18 8.36-15.883 14.211-25.079 14.211H64.786z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='182' fill='none' viewbox='0 0 200 182'%3E%3Cpath fill='%23000' d='M64.786 181.4c-9.196 0-20.063-6.687-25.079-14.21L3.762 105.33c-5.016-8.36-5.016-20.9 0-29.259l35.945-61.86C44.723 5.851 55.59 0 64.786 0h71.055c9.196 0 20.063 6.688 25.079 14.211l35.945 61.86c4.18 8.36 4.18 20.899 0 29.258l-35.945 61.86c-4.18 8.36-15.883 14.211-25.079 14.211H64.786z'/%3E%3C/svg%3E");
}

.mask.is-octagon {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='198' height='198' fill='none' viewbox='0 0 198 198'%3E%3Cpath fill='%23000' d='M91.346 2.17a20 20 0 0115.308 0l55.403 22.95a20 20 0 0110.824 10.823l22.949 55.403a20.004 20.004 0 010 15.308l-22.949 55.403a20.006 20.006 0 01-10.824 10.824l-55.403 22.949a20.004 20.004 0 01-15.308 0l-55.403-22.949a20 20 0 01-10.824-10.824L2.17 106.654a20 20 0 010-15.308l22.95-55.403a20 20 0 0110.823-10.824L91.346 2.17z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='198' height='198' fill='none' viewbox='0 0 198 198'%3E%3Cpath fill='%23000' d='M91.346 2.17a20 20 0 0115.308 0l55.403 22.95a20 20 0 0110.824 10.823l22.949 55.403a20.004 20.004 0 010 15.308l-22.949 55.403a20.006 20.006 0 01-10.824 10.824l-55.403 22.949a20.004 20.004 0 01-15.308 0l-55.403-22.949a20 20 0 01-10.824-10.824L2.17 106.654a20 20 0 010-15.308l22.95-55.403a20 20 0 0110.823-10.824L91.346 2.17z'/%3E%3C/svg%3E");
}

.mask.is-star {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='180' fill='none' viewbox='0 0 180 180'%3E%3Cpath fill='%23000' d='M82.39 2.111a14 14 0 0114.568 0l10.401 6.337a14 14 0 007.613 2.04l12.176-.287a14.003 14.003 0 0112.616 7.283l5.839 10.688a14.005 14.005 0 005.573 5.574l10.688 5.84a14.001 14.001 0 017.284 12.615l-.287 12.175a14.004 14.004 0 002.04 7.614l6.336 10.4a14 14 0 010 14.568l-6.336 10.401a14 14 0 00-2.04 7.613l.287 12.176a14.002 14.002 0 01-7.284 12.616l-10.688 5.839a14.005 14.005 0 00-5.573 5.573l-5.839 10.688a14.003 14.003 0 01-12.616 7.284l-12.176-.287a14 14 0 00-7.613 2.04l-10.401 6.336a13.998 13.998 0 01-14.567 0l-10.401-6.336a14.004 14.004 0 00-7.614-2.04l-12.175.287a13.994 13.994 0 01-12.616-7.284l-5.84-10.688a14.004 14.004 0 00-5.573-5.573l-10.688-5.839a14.001 14.001 0 01-7.283-12.616l.287-12.176a14 14 0 00-2.04-7.613L2.11 96.958a14 14 0 010-14.567L8.448 71.99a14 14 0 002.04-7.614L10.2 52.201a13.998 13.998 0 017.283-12.616l10.688-5.84a14 14 0 005.574-5.573l5.84-10.688A14 14 0 0152.2 10.201l12.175.287a14 14 0 007.614-2.04l10.4-6.337h.001z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='180' fill='none' viewbox='0 0 180 180'%3E%3Cpath fill='%23000' d='M82.39 2.111a14 14 0 0114.568 0l10.401 6.337a14 14 0 007.613 2.04l12.176-.287a14.003 14.003 0 0112.616 7.283l5.839 10.688a14.005 14.005 0 005.573 5.574l10.688 5.84a14.001 14.001 0 017.284 12.615l-.287 12.175a14.004 14.004 0 002.04 7.614l6.336 10.4a14 14 0 010 14.568l-6.336 10.401a14 14 0 00-2.04 7.613l.287 12.176a14.002 14.002 0 01-7.284 12.616l-10.688 5.839a14.005 14.005 0 00-5.573 5.573l-5.839 10.688a14.003 14.003 0 01-12.616 7.284l-12.176-.287a14 14 0 00-7.613 2.04l-10.401 6.336a13.998 13.998 0 01-14.567 0l-10.401-6.336a14.004 14.004 0 00-7.614-2.04l-12.175.287a13.994 13.994 0 01-12.616-7.284l-5.84-10.688a14.004 14.004 0 00-5.573-5.573l-10.688-5.839a14.001 14.001 0 01-7.283-12.616l.287-12.176a14 14 0 00-2.04-7.613L2.11 96.958a14 14 0 010-14.567L8.448 71.99a14 14 0 002.04-7.614L10.2 52.201a13.998 13.998 0 017.283-12.616l10.688-5.84a14 14 0 005.574-5.573l5.84-10.688A14 14 0 0152.2 10.201l12.175.287a14 14 0 007.614-2.04l10.4-6.337h.001z'/%3E%3C/svg%3E");
}

.mask.is-star-2 {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='187' height='187' fill='none' viewbox='0 0 187 187'%3E%3Cpath fill='%23000' d='M81.518 3.291a24.647 24.647 0 0123.93 0l18.134 10.07a24.65 24.65 0 005.188 2.148l19.943 5.703a24.645 24.645 0 0116.921 16.921l5.702 19.942a24.665 24.665 0 002.15 5.189l10.068 18.134a24.64 24.64 0 010 23.93l-10.068 18.134a24.66 24.66 0 00-2.15 5.188l-5.702 19.943a24.64 24.64 0 01-6.27 10.651 24.638 24.638 0 01-10.651 6.269l-19.943 5.703a24.66 24.66 0 00-5.188 2.15l-18.134 10.068a24.644 24.644 0 01-23.93 0l-18.134-10.068a24.663 24.663 0 00-5.188-2.15l-19.943-5.703a24.641 24.641 0 01-16.921-16.92L15.63 128.65a24.635 24.635 0 00-2.149-5.188l-10.07-18.134a24.651 24.651 0 010-23.93l10.07-18.134a24.644 24.644 0 002.148-5.188l5.704-19.943a24.647 24.647 0 0116.92-16.921l19.943-5.703a24.655 24.655 0 005.188-2.148L81.518 3.29z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='187' height='187' fill='none' viewbox='0 0 187 187'%3E%3Cpath fill='%23000' d='M81.518 3.291a24.647 24.647 0 0123.93 0l18.134 10.07a24.65 24.65 0 005.188 2.148l19.943 5.703a24.645 24.645 0 0116.921 16.921l5.702 19.942a24.665 24.665 0 002.15 5.189l10.068 18.134a24.64 24.64 0 010 23.93l-10.068 18.134a24.66 24.66 0 00-2.15 5.188l-5.702 19.943a24.64 24.64 0 01-6.27 10.651 24.638 24.638 0 01-10.651 6.269l-19.943 5.703a24.66 24.66 0 00-5.188 2.15l-18.134 10.068a24.644 24.644 0 01-23.93 0l-18.134-10.068a24.663 24.663 0 00-5.188-2.15l-19.943-5.703a24.641 24.641 0 01-16.921-16.92L15.63 128.65a24.635 24.635 0 00-2.149-5.188l-10.07-18.134a24.651 24.651 0 010-23.93l10.07-18.134a24.644 24.644 0 002.148-5.188l5.704-19.943a24.647 24.647 0 0116.92-16.921l19.943-5.703a24.655 24.655 0 005.188-2.148L81.518 3.29z'/%3E%3C/svg%3E");
}

.mask.is-heart {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='185' fill='none' viewbox='0 0 200 185'%3E%3Cpath fill='%23000' d='M100 184.606a15.382 15.382 0 01-8.653-2.678C53.565 156.28 37.205 138.695 28.182 127.7 8.952 104.264-.254 80.202.005 54.146.308 24.287 24.264 0 53.406 0c21.192 0 35.869 11.937 44.416 21.879a2.884 2.884 0 004.356 0C110.725 11.927 125.402 0 146.594 0c29.142 0 53.098 24.287 53.4 54.151.26 26.061-8.956 50.122-28.176 73.554-9.023 10.994-25.383 28.58-63.165 54.228a15.388 15.388 0 01-8.653 2.673z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='185' fill='none' viewbox='0 0 200 185'%3E%3Cpath fill='%23000' d='M100 184.606a15.382 15.382 0 01-8.653-2.678C53.565 156.28 37.205 138.695 28.182 127.7 8.952 104.264-.254 80.202.005 54.146.308 24.287 24.264 0 53.406 0c21.192 0 35.869 11.937 44.416 21.879a2.884 2.884 0 004.356 0C110.725 11.927 125.402 0 146.594 0c29.142 0 53.098 24.287 53.4 54.151.26 26.061-8.956 50.122-28.176 73.554-9.023 10.994-25.383 28.58-63.165 54.228a15.388 15.388 0 01-8.653 2.673z'/%3E%3C/svg%3E");
}

.steps {
  --size: 2rem;
  --line: .25rem;
  align-items: baseline;
  display: flex;
}

.steps.line-space {
  --space: .5rem;
}

.steps .step {
  overflow-wrap: break-word;
  text-align: center;
  letter-spacing: .025em;
  flex-direction: column;
  flex: 1;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  display: flex;
}

.steps .step .step-header {
  height: var(--size);
  width: var(--size);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: auto auto .5rem;
  display: flex;
  position: relative;
}

.steps .step:not(:last-child):before {
  left: 50%;
  top: calc(var(--size) / 2 + var(--line) / 2);
  height: var(--line);
  --tw-content: "";
  content: var(--tw-content);
  border-radius: 9999px;
  order: -1;
  position: relative;
}

.steps.line-space .step:not(:last-child):before {
  width: calc(100% - var(--size)  - calc(var(--space) * 2));
  left: calc(50% + calc(var(--size) / 2 + var(--space)));
}

.steps.is-vertical {
  flex-direction: column;
}

.steps.is-vertical .step {
  flex-direction: row;
  position: relative;
}

.steps.is-vertical .step .step-header {
  z-index: 1;
  margin: 0;
}

.steps.is-vertical .step:not(:last-child):before {
  height: auto;
  width: var(--line);
  --tw-translate-x: calc((var(--size) / 2)  - (var(--line) / 2));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  inset: 0;
}

.steps.is-vertical.line-space .step:not(:last-child):before {
  top: calc(var(--size)  + var(--space));
  bottom: var(--space);
}

.pagination {
  color: #475569;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  display: flex;
}

.dark .pagination {
  color: #c2c9d6;
}

table.is-hoverable > tbody > tr:hover > td, table.is-zebra > tbody > tr:nth-child(2n) > td {
  background-color: #f1f5f9;
}

.dark table.is-hoverable > tbody > tr:hover > td, .dark table.is-zebra > tbody > tr:nth-child(2n) > td {
  background-color: #313e59;
}

.gridjs-table {
  width: 100%;
  text-align: left;
}

.gridjs-table > tbody > tr:hover > td {
  background-color: #f1f5f9;
}

.dark .gridjs-table > tbody > tr:hover > td {
  background-color: #313e59;
}

th.gridjs-th .gridjs-th-content {
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gridjs-table .gridjs-th {
  white-space: nowrap;
  text-transform: uppercase;
  color: #1e293b;
  background-color: #e2e8f0;
  padding: .75rem;
  font-weight: 600;
}

.dark .gridjs-table .gridjs-th {
  color: #c2c9d6;
  background-color: #202b40;
}

@media (min-width: 1024px) {
  .gridjs-table .gridjs-th {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.gridjs-table .gridjs-tr {
  border-width: 1px;
  border-color: #0000 #0000 #e2e8f0;
}

.dark .gridjs-table .gridjs-tr {
  border-bottom-color: #384766;
}

.gridjs-table .gridjs-td {
  white-space: nowrap;
  padding: .75rem 1rem;
}

@media (min-width: 640px) {
  .gridjs-table .gridjs-td {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

button.gridjs-sort {
  --neutral: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 401.998 401.998'%3E%3Cpath d='M73.092,164.452h255.813c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.427-7.898,5.427-12.847%0Ac0-4.949-1.813-9.229-5.427-12.85L213.846,5.424C210.232,1.812,205.951,0,200.999,0s-9.233,1.812-12.85,5.424L60.242,133.331%0Ac-3.617,3.617-5.424,7.901-5.424,12.85c0,4.948,1.807,9.231,5.424,12.847C63.863,162.645,68.144,164.452,73.092,164.452z'/%3E%3Cpath d='M328.905,237.549H73.092c-4.952,0-9.233,1.808-12.85,5.421c-3.617,3.617-5.424,7.898-5.424,12.847%0Ac0,4.949,1.807,9.233,5.424,12.848L188.149,396.57c3.621,3.617,7.902,5.428,12.85,5.428s9.233-1.811,12.847-5.428l127.907-127.906%0Ac3.613-3.614,5.427-7.898,5.427-12.848c0-4.948-1.813-9.229-5.427-12.847C338.139,239.353,333.854,237.549,328.905,237.549z'/%3E%3C/svg%3E");
  --asc: url("data:image/svg+xml,%3Csvg fill='currentColor' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 292.362 292.361'%3E%3Cpath d='M286.935,197.287L159.028,69.381c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233,1.807-12.85,5.424L5.424,197.287 C1.807,200.904,0,205.186,0,210.134s1.807,9.233,5.424,12.847c3.621,3.617,7.902,5.425,12.85,5.425h255.813 c4.949,0,9.233-1.808,12.848-5.425c3.613-3.613,5.427-7.898,5.427-12.847S290.548,200.904,286.935,197.287z'/%3E%3C/svg%3E");
  --desc: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 292.362 292.362'%3E%3Cpath d='M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424 C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428 s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z'/%3E%3C/svg%3E");
}

th.gridjs-th-sort {
  cursor: pointer;
}

th.gridjs-th-sort:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

th.gridjs-th-sort .gridjs-th-content {
  width: calc(100% - 15px);
}

button.gridjs-sort {
  float: right;
  height: .75rem;
  width: .75rem;
  cursor: pointer;
  outline-offset: 2px;
  background-repeat: no-repeat;
  border-style: none;
  outline: 2px solid #0000;
  margin: .25rem 0 0;
  padding: 0;
}

button.gridjs-sort-neutral {
  -webkit-mask-image: var(--neutral);
  mask-image: var(--neutral);
  background-color: #94a3b8;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.dark button.gridjs-sort-neutral {
  background-color: #5c6b8a;
}

button.gridjs-sort-asc {
  -webkit-mask-image: var(--asc);
  mask-image: var(--asc);
  -webkit-mask-size: cover;
  mask-size: cover;
}

button.gridjs-sort-desc {
  -webkit-mask-image: var(--desc);
  mask-image: var(--desc);
  -webkit-mask-size: cover;
  mask-size: cover;
}

button.gridjs-sort-asc, button.gridjs-sort-desc {
  background-color: #475569;
}

.dark button.gridjs-sort-asc, .dark button.gridjs-sort-desc {
  background-color: #c2c9d6;
}

.gridjs-input.gridjs-search-input {
  appearance: none;
  outline-offset: 2px;
  border-width: 1px;
  border-color: #cbd5e1;
  border-radius: .5rem;
  outline: 2px solid #0000;
  padding: .5rem .75rem;
  font-weight: 500;
}

.dark .gridjs-input.gridjs-search-input {
  color: #c2c9d6;
  background-color: #26334d;
  border-color: #465675;
}

.gridjs-pages {
  flex-wrap: wrap;
  display: flex;
}

.gridjs-pages > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.gridjs-pages {
  color: #475569;
}

.dark .gridjs-pages {
  color: #cbd5e1;
}

.gridjs-pages button {
  height: 2rem;
  min-width: 2rem;
  background-color: #e9eef5;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  padding-left: .75rem;
  padding-right: .75rem;
  line-height: 1.25;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.gridjs-pages button:hover, .gridjs-pages button:focus {
  background-color: #cbd5e1;
}

.gridjs-pages button:disabled {
  pointer-events: none;
  opacity: .6;
}

.dark .gridjs-pages button {
  background-color: #384766;
}

.dark .gridjs-pages button:hover, .dark .gridjs-pages button:focus {
  background-color: #465675;
}

.gridjs-pages button.gridjs-currentPage {
  color: #fff;
  background-color: #4f46e5;
}

.dark .gridjs-pages button.gridjs-currentPage {
  background-color: #5f5af6;
}

.gridjs-wrapper {
  min-width: 100%;
  overflow-x: auto;
}

.gridjs-search {
  justify-content: flex-end;
  padding-bottom: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

@media (min-width: 640px) {
  .gridjs-search {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.gridjs-pagination {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.gridjs-pagination > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.gridjs-pagination {
  padding: 1rem;
}

@media (min-width: 640px) {
  .gridjs-pagination {
    flex-direction: row;
    align-items: center;
  }

  .gridjs-pagination > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .gridjs-pagination {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.gridjs-loading {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.tab-content.tab-shift-left {
  animation: cubic-bezier(var(--ease-in-out)) tab-shift-left .3s forwards;
}

.tab-content.tab-shift-up {
  animation: cubic-bezier(var(--ease-in-out)) tab-shift-up .3s forwards;
}

.tab-content:not(.is-active) {
  display: none !important;
}

@keyframes tab-shift-left {
  from {
    opacity: 0;
    transform: translate3d(1rem, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes tab-shift-up {
  from {
    opacity: 0;
    transform: translate3d(0, 1rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.ac-panel {
  overflow: hidden;
}

.modal:not(.show) {
  display: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modal {
  animation: cubic-bezier(var(--ease-out)) fade-in .3s;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.modal.animate\:leave {
  animation: cubic-bezier(var(--ease-in)) fade-out .3s;
}

.modal.shift-up {
  --modal-to: translate3d(0, 0, 0);
  --modal-from: translate3d(0, 1rem, 0);
}

.modal.shift-down {
  --modal-to: translate3d(0, 0, 0);
  --modal-from: translate3d(0, -1rem, 0);
}

.modal.modal-scale {
  --modal-to: scale(1);
  --modal-from: scale(.95);
}

.modal.shift-up .modal-content, .modal.shift-down .modal-content, .modal.modal-scale .modal-content {
  animation: cubic-bezier(var(--ease-out)) modal-open .3s;
}

.modal.shift-up.animate\:leave .modal-content, .modal.shift-down.animate\:leave .modal-content, .modal.modal-scale.animate\:leave .modal-content {
  animation: cubic-bezier(var(--ease-in)) modal-close .3s;
}

@keyframes modal-open {
  from {
    transform: var(--modal-from);
  }

  to {
    transform: var(--modal-to);
  }
}

@keyframes modal-close {
  from {
    transform: var(--modal-to);
  }

  to {
    transform: var(--modal-from);
  }
}

.drawer {
  --drawer-from: translate(-100%);
  --drawer-to: translate(0%);
}

.drawer.drawer-right {
  --drawer-to: translate(0%);
  --drawer-from: translate(100%);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.drawer .drawer-overlay {
  animation: cubic-bezier(var(--ease-out)) fade-in .2s;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.drawer .drawer-overlay.animate\:leave {
  animation: cubic-bezier(var(--ease-in)) fade-out .2s;
}

.drawer .drawer-content {
  animation: cubic-bezier(var(--ease-out)) drawer-open .2s;
}

.drawer .drawer-content.animate\:leave {
  animation: cubic-bezier(var(--ease-in)) drawer-close .2s;
}

@keyframes drawer-open {
  from {
    transform: var(--drawer-from);
  }

  to {
    transform: var(--drawer-to);
  }
}

@keyframes drawer-close {
  from {
    transform: var(--drawer-to);
  }

  to {
    transform: var(--drawer-from);
  }
}



*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.m-2 {
  margin: .5rem;
}

.m-0 {
  margin: 0;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 1rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-0 {
  margin-top: 0;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-0 {
  margin-left: 0;
}

.mt-5 {
  margin-top: 1.25rem;
}

.-mr-1\.5 {
  margin-right: -.375rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-1\.5 {
  height: .375rem;
}

.h-1 {
  height: .25rem;
}

.h-11 {
  height: 2.75rem;
}

.h-6 {
  height: 1.5rem;
}

.h-px {
  height: 1px;
}

.h-5 {
  height: 1.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-full {
  height: 100%;
}

.h-8 {
  height: 2rem;
}

.h-4 {
  height: 1rem;
}

.h-screen {
  height: 100vh;
}

.h-64 {
  height: 16rem;
}

.h-2 {
  height: .5rem;
}

.max-h-60 {
  max-height: 15rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-5 {
  width: 1.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-48 {
  width: 12rem;
}

.w-1\/2 {
  width: 50%;
}

.w-7 {
  width: 1.75rem;
}

.w-6 {
  width: 1.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-8 {
  width: 2rem;
}

.w-4 {
  width: 1rem;
}

.w-screen {
  width: 100vw;
}

.w-32 {
  width: 8rem;
}

.w-4\/12 {
  width: 33.3333%;
}

.min-w-full {
  min-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-\[6px\] {
  border-width: 6px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-l-0 {
  border-left-width: 0;
}

.border-r {
  border-right-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-dashed {
  border-style: dashed;
}

.border-slate-150 {
  border-color: #e9eef5;
}

.border-slate-200 {
  border-color: #e2e8f0;
}

.border-slate-300 {
  border-color: #cbd5e1;
}

.border-slate-600 {
  border-color: #475569;
}

.border-emerald-600 {
  border-color: #059669;
}

.border-neutral-200 {
  border-color: #e5e5e5;
}

.border-info\/30 {
  border-color: #0ea5e94d;
}

.border-slate-500 {
  border-color: #64748b;
}

.border-slate-400 {
  border-color: #94a3b8;
}

.border-transparent {
  border-color: #0000;
}

.border-primary {
  border-color: #4f46e5;
}

.border-slate-100 {
  border-color: #f1f5f9;
}

.border-warning {
  border-color: #ff9800;
}

.border-info {
  border-color: #0ea5e9;
}

.border-slate-400\/70 {
  border-color: #94a3b8b3;
}

.border-error {
  border-color: #ff5724;
}

.border-warning\/30 {
  border-color: #ff98004d;
}

.border-r-slate-500 {
  border-right-color: #64748b;
}

.border-r-info {
  border-right-color: #0ea5e9;
}

.border-r-transparent {
  border-right-color: #0000;
}

.bg-slate-50 {
  background-color: #f8fafc;
}

.bg-slate-150 {
  background-color: #e9eef5;
}

.bg-accent {
  background-color: #5f5af6;
}

.bg-primary {
  background-color: #4f46e5;
}

.bg-white {
  background-color: #fff;
}

.bg-slate-200 {
  background-color: #e2e8f0;
}

.bg-success {
  background-color: #10b981;
}

.bg-emerald-50 {
  background-color: #ecfdf5;
}

.bg-primary\/10 {
  background-color: #4f46e51a;
}

.bg-warning {
  background-color: #ff9800;
}

.bg-slate-100 {
  background-color: #f1f5f9;
}

.bg-info\/10 {
  background-color: #0ea5e91a;
}

.bg-black\/40 {
  background-color: #0006;
}

.bg-emerald-500 {
  background-color: #10b981;
}

.bg-secondary {
  background-color: #f000b9;
}

.bg-transparent {
  background-color: #0000;
}

.bg-info {
  background-color: #0ea5e9;
}

.bg-accent-light {
  background-color: #818cf8;
}

.bg-slate-300 {
  background-color: #cbd5e1;
}

.bg-warning\/10 {
  background-color: #ff98001a;
}

.bg-slate-500 {
  background-color: #64748b;
}

.bg-success\/10 {
  background-color: #10b9811a;
}

.bg-error {
  background-color: #ff5724;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.from-fuchsia-600 {
  --tw-gradient-from: #c026d3;
  --tw-gradient-to: #c026d300;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500 {
  --tw-gradient-from: #a855f7;
  --tw-gradient-to: #a855f700;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400 {
  --tw-gradient-from: #38bdf8;
  --tw-gradient-to: #38bdf800;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500 {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-to: #f59e0b00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-pink-600 {
  --tw-gradient-to: #db2777;
}

.to-indigo-600 {
  --tw-gradient-to: #4f46e5;
}

.to-blue-600 {
  --tw-gradient-to: #2563eb;
}

.to-amber-600 {
  --tw-gradient-to: #d97706;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-2 {
  padding: .5rem;
}

.p-1 {
  padding: .25rem;
}

.p-3 {
  padding: .75rem;
}

.p-0 {
  padding: 0;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-\[var\(--margin-x\)\] {
  padding-left: var(--margin-x);
  padding-right: var(--margin-x);
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-sans {
  font-family: Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-inter {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xs\+ {
  font-size: .8125rem;
  line-height: 1.125rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-tiny {
  font-size: .625rem;
  line-height: .8125rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-tiny\+ {
  font-size: .6875rem;
  line-height: .875rem;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-light {
  font-weight: 300;
}

.uppercase {
  text-transform: uppercase;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-slate-800 {
  color: #1e293b;
}

.text-slate-700 {
  color: #334155;
}

.text-slate-400 {
  color: #94a3b8;
}

.text-white {
  color: #fff;
}

.text-primary {
  color: #4f46e5;
}

.text-slate-500 {
  color: #64748b;
}

.text-slate-600 {
  color: #475569;
}

.text-emerald-800 {
  color: #065f46;
}

.text-neutral-500 {
  color: #737373;
}

.text-info {
  color: #0ea5e9;
}

.text-pink-400 {
  color: #f472b6;
}

.text-black {
  color: #000;
}

.text-slate-200 {
  color: #e2e8f0;
}

.text-red-500 {
  color: #ef4444;
}

.text-slate-300 {
  color: #cbd5e1;
}

.text-success {
  color: #10b981;
}

.text-error {
  color: #ff5724;
}

.text-neutral-800 {
  color: #262626;
}

.text-gray-400 {
  color: #9ca3af;
}

.text-gray-900 {
  color: #111827;
}

.text-blue-600 {
  color: #2563eb;
}

.text-warning {
  color: #ff9800;
}

.underline {
  text-decoration-line: underline;
}

.decoration-solid {
  text-decoration-style: solid;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-80 {
  opacity: .8;
}

.shadow-soft {
  --tw-shadow: 0 3px 10px 0 #302e380f;
  --tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-75 {
  transition-delay: 75ms;
}

.duration-\[\.25s\] {
  transition-duration: .25s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.\[--size\:\.75rem\] {
  --size: .75rem;
}

.\[--line\:1px\] {
  --line: 1px;
}

.placeholder\:text-slate-500::placeholder {
  color: #64748b;
}

.placeholder\:text-slate-400\/70::placeholder {
  color: #94a3b8b3;
}

.before\:bg-slate-200:before {
  content: var(--tw-content);
  background-color: #e2e8f0;
}

.first\:mt-0:first-child {
  margin-top: 0;
}

.last\:mr-0:last-child {
  margin-right: 0;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.checked\:border-slate-500:checked {
  border-color: #64748b;
}

.checked\:border-primary:checked {
  border-color: #4f46e5;
}

.checked\:bg-slate-500:checked {
  background-color: #64748b;
}

.checked\:bg-primary:checked {
  background-color: #4f46e5;
}

.hover\:z-10:hover {
  z-index: 10;
}

.hover\:rotate-\[360deg\]:hover {
  --tw-rotate: 360deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-slate-400:hover {
  border-color: #94a3b8;
}

.hover\:border-slate-500:hover {
  border-color: #64748b;
}

.hover\:border-primary:hover {
  border-color: #4f46e5;
}

.hover\:bg-primary-focus:hover {
  background-color: #4338ca;
}

.hover\:bg-slate-200:hover {
  background-color: #e2e8f0;
}

.hover\:bg-success-focus:hover {
  background-color: #059669;
}

.hover\:bg-primary\/20:hover {
  background-color: #4f46e533;
}

.hover\:bg-slate-150:hover {
  background-color: #e9eef5;
}

.hover\:bg-slate-300\/20:hover {
  background-color: #cbd5e133;
}

.hover\:bg-emerald-700:hover {
  background-color: #047857;
}

.hover\:bg-slate-400:hover {
  background-color: #94a3b8;
}

.hover\:bg-error\/20:hover {
  background-color: #ff572433;
}

.hover\:bg-warning-focus:hover {
  background-color: #e68200;
}

.hover\:bg-slate-100:hover {
  background-color: #f1f5f9;
}

.hover\:text-pink-400:hover {
  color: #f472b6;
}

.hover\:text-slate-800:hover {
  color: #1e293b;
}

.hover\:text-white:hover {
  color: #fff;
}

.hover\:text-primary-focus:hover {
  color: #4338ca;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-primary\/50:hover {
  --tw-shadow-color: #4f46e580;
  --tw-shadow: var(--tw-shadow-colored);
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-primary:focus {
  border-color: #4f46e5;
}

.focus\:border-slate-500:focus {
  border-color: #64748b;
}

.focus\:bg-primary-focus:focus {
  background-color: #4338ca;
}

.focus\:bg-success-focus:focus {
  background-color: #059669;
}

.focus\:bg-primary\/20:focus {
  background-color: #4f46e533;
}

.focus\:bg-slate-150:focus {
  background-color: #e9eef5;
}

.focus\:bg-slate-300\/20:focus {
  background-color: #cbd5e133;
}

.focus\:bg-error\/20:focus {
  background-color: #ff572433;
}

.focus\:bg-warning-focus:focus {
  background-color: #e68200;
}

.focus\:bg-slate-200:focus {
  background-color: #e2e8f0;
}

.focus\:bg-slate-100:focus {
  background-color: #f1f5f9;
}

.focus\:text-slate-800:focus {
  color: #1e293b;
}

.focus\:shadow-lg:focus {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-primary\/50:focus {
  --tw-shadow-color: #4f46e580;
  --tw-shadow: var(--tw-shadow-colored);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.active\:bg-primary-focus\/90:active {
  background-color: #4338cae6;
}

.active\:bg-primary\/25:active {
  background-color: #4f46e540;
}

.active\:bg-slate-150\/80:active {
  background-color: #e9eef5cc;
}

.active\:bg-slate-300\/25:active {
  background-color: #cbd5e140;
}

.active\:bg-warning-focus\/90:active {
  background-color: #e68200e6;
}

.active\:bg-slate-200\/80:active {
  background-color: #e2e8f0cc;
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:select-none:disabled {
  -webkit-user-select: none;
  user-select: none;
}

.disabled\:opacity-60:disabled {
  opacity: .6;
}

.peer:focus ~ .peer-focus\:text-primary {
  color: #4f46e5;
}

.dark .dark\:border-navy-600 {
  border-color: #313e59;
}

.dark .dark\:border-navy-500 {
  border-color: #384766;
}

.dark .dark\:border-navy-700 {
  border-color: #26334d;
}

.dark .dark\:border-navy-800 {
  border-color: #202b40;
}

.dark .dark\:border-navy-300 {
  border-color: #697a9b;
}

.dark .dark\:border-navy-450 {
  border-color: #465675;
}

.dark .dark\:border-accent {
  border-color: #5f5af6;
}

.dark .dark\:border-navy-400 {
  border-color: #5c6b8a;
}

.dark .dark\:border-r-navy-300 {
  border-right-color: #697a9b;
}

.dark .dark\:border-r-transparent {
  border-right-color: #0000;
}

.dark .dark\:bg-navy-900 {
  background-color: #192132;
}

.dark .dark\:bg-navy-500 {
  background-color: #384766;
}

.dark .dark\:bg-accent {
  background-color: #5f5af6;
}

.dark .dark\:bg-navy-700 {
  background-color: #26334d;
}

.dark .dark\:bg-navy-800 {
  background-color: #202b40;
}

.dark .dark\:bg-accent-light\/15 {
  background-color: #818cf826;
}

.dark .dark\:bg-info\/15 {
  background-color: #0ea5e926;
}

.dark .dark\:bg-accent\/10 {
  background-color: #5f5af61a;
}

.dark .dark\:bg-accent-light\/10 {
  background-color: #818cf81a;
}

.dark .dark\:bg-secondary-light {
  background-color: #ff57d8;
}

.dark .dark\:bg-navy-400 {
  background-color: #5c6b8a;
}

.dark .dark\:bg-success\/15 {
  background-color: #10b98126;
}

.dark .dark\:text-navy-50 {
  color: #e7e9ef;
}

.dark .dark\:text-navy-100 {
  color: #c2c9d6;
}

.dark .dark\:text-navy-300 {
  color: #697a9b;
}

.dark .dark\:text-accent {
  color: #5f5af6;
}

.dark .dark\:text-navy-200 {
  color: #a3adc2;
}

.dark .dark\:text-accent-light {
  color: #818cf8;
}

.dark .dark\:text-accent-light\/80 {
  color: #818cf8cc;
}

.dark .dark\:shadow-soft-dark {
  --tw-shadow: 0 3px 10px 0 #1921324d;
  --tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dark .dark\:placeholder\:text-navy-200::placeholder {
  color: #a3adc2;
}

.dark .dark\:before\:bg-navy-500:before {
  content: var(--tw-content);
  background-color: #384766;
}

.dark .dark\:checked\:border-accent:checked {
  border-color: #5f5af6;
}

.dark .dark\:checked\:bg-navy-400:checked {
  background-color: #5c6b8a;
}

.dark .dark\:checked\:bg-accent:checked {
  background-color: #5f5af6;
}

.dark .dark\:hover\:border-navy-400:hover {
  border-color: #5c6b8a;
}

.dark .dark\:hover\:border-accent:hover {
  border-color: #5f5af6;
}

.dark .dark\:hover\:bg-accent-focus:hover {
  background-color: #4d47f5;
}

.dark .dark\:hover\:bg-navy-300\/20:hover {
  background-color: #697a9b33;
}

.dark .dark\:hover\:bg-navy-500:hover {
  background-color: #384766;
}

.dark .dark\:hover\:bg-accent-light\/20:hover {
  background-color: #818cf833;
}

.dark .dark\:hover\:bg-navy-450:hover {
  background-color: #465675;
}

.dark .dark\:hover\:bg-navy-600:hover {
  background-color: #313e59;
}

.dark .dark\:hover\:text-navy-100:hover {
  color: #c2c9d6;
}

.dark .dark\:hover\:text-accent:hover {
  color: #5f5af6;
}

.dark .dark\:hover\:shadow-accent\/50:hover {
  --tw-shadow-color: #5f5af680;
  --tw-shadow: var(--tw-shadow-colored);
}

.dark .dark\:focus\:border-accent:focus {
  border-color: #5f5af6;
}

.dark .dark\:focus\:bg-accent-focus:focus {
  background-color: #4d47f5;
}

.dark .dark\:focus\:bg-navy-300\/20:focus {
  background-color: #697a9b33;
}

.dark .dark\:focus\:bg-navy-500:focus {
  background-color: #384766;
}

.dark .dark\:focus\:bg-accent-light\/20:focus {
  background-color: #818cf833;
}

.dark .dark\:focus\:bg-navy-450:focus {
  background-color: #465675;
}

.dark .dark\:focus\:bg-navy-600:focus {
  background-color: #313e59;
}

.dark .dark\:focus\:text-navy-100:focus {
  color: #c2c9d6;
}

.dark .dark\:focus\:shadow-accent\/50:focus {
  --tw-shadow-color: #5f5af680;
  --tw-shadow: var(--tw-shadow-colored);
}

.dark .dark\:active\:bg-accent\/90:active {
  background-color: #5f5af6e6;
}

.dark .dark\:active\:bg-navy-300\/25:active {
  background-color: #697a9b40;
}

.dark .dark\:active\:bg-navy-500\/90:active {
  background-color: #384766e6;
}

.dark .dark\:active\:bg-accent-light\/25:active {
  background-color: #818cf840;
}

.dark .dark\:active\:bg-navy-450\/90:active {
  background-color: #465675e6;
}

.dark .peer:focus ~ .dark\:peer-focus\:text-accent {
  color: #5f5af6;
}

@media print {
  .print\:hidden {
    display: none;
  }
}

@media (min-width: 640px) {
  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:gap-5 {
    gap: 1.25rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:mr-2 {
    margin-right: .5rem;
  }

  .lg\:ml-2 {
    margin-left: .5rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-48 {
    width: 12rem;
  }

  .lg\:max-w-md {
    max-width: 28rem;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: .9;
}

.core-styles-module_tooltip__3vRRp {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  will-change: opacity, visibility;
  transition: opacity .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.core-styles-module_fixed__pcSol {
  position: fixed;
}

.core-styles-module_arrow__cvMwQ {
  background: inherit;
  position: absolute;
}

.core-styles-module_noArrow__xock6 {
  display: none;
}

.core-styles-module_clickable__ZuTTB {
  pointer-events: auto;
}

.core-styles-module_show__Nt9eE {
  visibility: visible;
  opacity: var(--rt-opacity);
}

.styles-module_tooltip__mnnfp {
  width: max-content;
  border-radius: 3px;
  padding: 8px 16px;
  font-size: 90%;
}

.styles-module_arrow__K0L3T {
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}

.styles-module_dark__xNqje {
  background: var(--rt-color-dark);
  color: var(--rt-color-white);
}

.styles-module_light__Z6W-X {
  background-color: var(--rt-color-white);
  color: var(--rt-color-dark);
}

.styles-module_success__A2AKt {
  background-color: var(--rt-color-success);
  color: var(--rt-color-white);
}

.styles-module_warning__SCK0X {
  background-color: var(--rt-color-warning);
  color: var(--rt-color-white);
}

.styles-module_error__JvumD {
  background-color: var(--rt-color-error);
  color: var(--rt-color-white);
}

.styles-module_info__BWdHW {
  background-color: var(--rt-color-info);
  color: var(--rt-color-white);
}

/*# sourceMappingURL=index.4dfb4697.css.map */
