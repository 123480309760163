@import "./components/card.css";
@import "./components/skeleton.css";
@import "./components/avatar.css";
@import "./components/spinner.css";
/* @import "./components/popper.css"; */
@import "./components/form.css";
/* @import "./components/tom-select.css"; */
@import "./components/button.css";
@import "./components/badge.css";
/* @import "./components/simplebar.css"; */
@import "./components/progress.css";
@import "./components/mask.css";
/* @import "./components/tooltip.css"; */
/* @import "./components/filepond.css"; */
/* @import "./components/quill.css"; */
/* @import "./components/flatpickr.css"; */
/* @import "./components/notification.css"; */
@import "./components/steps.css";
/* @import "./components/timeline.css"; */
@import "./components/pagination.css";
/* @import "./components/swiper.css"; */
/* @import "./components/apexcharts.css"; */
@import "./components/table.css";
@import "./components/tableGrid.css";
@import "./components/tabs.css";
@import "./components/collapse.css";
@import "./components/modal.css";
@import "./components/drawer.css";
